export type HealthState = 'Ambulatory' | 'Non-ambulatory' | 'On ventilation' | 'Mixed';
type HealthSubState =
    'Early ambulatory (age 5-7 y)' |
    'Late ambulatory (age 8-11 y)' |
    'Any ambulatory' |
    'Early non-ambulatory (age 12-15 y)' |
    'Late non-ambulatory (age 16+ y)' |
    'Overall; age not specified' |
    'Any non-ambulatory' |
    'Ventilation type NR' |
    'Adults with DMD; 96% on ventilation type NR' |
    'No ventilation' |
    'Night-time ventilation' |
    'Day- and night-time ventilation' |
    '70% <17 y' |
    'Any DMD (rs)';

export type Country = 'Bulgaria' | 'France' | 'Germany' | 'Hungary' | 'Italy' | 'Spain' | 'Sweden' | 'UK' | 'US' | 'Netherlands';
type AgeGroup = 0 | 1 | 2; // 0-10, 11-17, 18+
export type Respondent = 'Patient' | 'Caregiver';
export type Instrument = 'HUI-3' | 'EQ-5D' | 'EQ-5D VAS';
export type UtilitySource = 'Proxy' | 'Patient';
type Source =
    'Landfeldt et al., 2014 [24]' |
    'Landfeldt et al., 2015 [25]' |
    'Landfeldt et al., 2016 [22]' |
    'Landfeldt et al., 2016 [23]' |
    'Landfeldt et al., 2018 [27]' |
    'Pangalila et al., 2012 [29]' |
    'Cavazza et al., 2016 [26]';


export interface Entry {
    healthState: HealthState;
    healthSubState: HealthSubState;
    countries: Country[];
    ageGroup: AgeGroup;
    respondent: Respondent;
    instrument: Instrument;
    utilitySource: UtilitySource;
    source: Source;
    meanUtility: number;
    randomOffset: number;
    age: string;
    sd?: number;
}


const getRandomArbitrary = (min: number, max: number): number => Math.random() * (max - min) + min;

const getRandomSign = (): number => {
    if (Math.random() > 0.5) {
        return 1;
    }
    return -1;
};

const randomOffset = (): number => getRandomArbitrary(0, 5) * getRandomSign();

// {
//     healthState: '',
//     countries: [],
//     ageGroup: ,
//     respondent: '',
//     instrument: '',
//     utilitySource: '',
//     source: '',
//     meanUtility:
// },
export const data: Entry[] = [
    // Patient Respondents
    {
        // Excel Row: 4
        healthSubState: 'Early ambulatory (age 5-7 y)',
        healthState: 'Ambulatory',
        countries: ['Germany', 'Italy', 'UK', 'US'],
        ageGroup: 0,
        respondent: 'Patient',
        instrument: 'HUI-3',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2014 [24]',
        meanUtility: 0.75,
        randomOffset: randomOffset(),
        age: '5-7'
    },
    {
        // Excel Row: 6
        healthSubState: 'Late ambulatory (age 8-11 y)',
        healthState: 'Ambulatory',
        countries: ['Germany', 'Italy', 'UK', 'US'],
        ageGroup: 0,
        respondent: 'Patient',
        instrument: 'HUI-3',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2014 [24]',
        meanUtility: 0.65,
        randomOffset: randomOffset(),
        age: '8-11'
    },
    {
        // Excel Row: 8
        healthSubState: 'Any ambulatory',
        healthState: 'Ambulatory',
        countries: ['Germany', 'Italy', 'UK', 'US'],
        ageGroup: 0,
        respondent: 'Patient',
        instrument: 'HUI-3',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2014 [24]', // Estimate
        meanUtility: 0.69,
        randomOffset: randomOffset(),
        age: '5-11'
    },
    {
        // Excel Row: 10
        healthSubState: 'Early non-ambulatory (age 12-15 y)',
        healthState: 'Non-ambulatory',
        countries: ['Germany', 'Italy', 'UK', 'US'],
        ageGroup: 1,
        respondent: 'Patient',
        instrument: 'HUI-3',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2014 [24]',
        meanUtility: 0.24,
        randomOffset: randomOffset(),
        age: '12-15'
    },
    {
        // Excel Row: 12
        healthSubState: 'Late non-ambulatory (age 16+ y)',
        healthState: 'Non-ambulatory',
        countries: ['Germany', 'Italy', 'UK', 'US'],
        ageGroup: 1,
        respondent: 'Patient',
        instrument: 'HUI-3',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2014 [24]',
        meanUtility: 0.15,
        randomOffset: randomOffset(),
        age: '16+'
    },
    {
        // Excel Row: 14
        healthSubState: 'Overall; age not specified',
        healthState: 'Non-ambulatory',
        countries: ['UK', 'US'],
        ageGroup: 1,
        respondent: 'Patient',
        instrument: 'HUI-3',
        utilitySource: 'Patient',
        source: 'Landfeldt et al., 2018 [27]',
        meanUtility: 0.36,
        sd: 0.28,
        randomOffset: randomOffset(),
        age: '&#8805;12'
    },
    {
        // Excel Row: 15
        healthSubState: 'Any non-ambulatory',
        healthState: 'Non-ambulatory',
        countries: ['Germany', 'Italy', 'UK', 'US'],
        ageGroup: 1,
        respondent: 'Patient',
        instrument: 'HUI-3',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2014 [24]', // Estimate
        meanUtility: 0.19,
        randomOffset: randomOffset(),
        age: '&#8805;12'
    },
    {
        // Excel Row: 17
        healthSubState: 'Ventilation type NR',
        healthState: 'On ventilation',
        countries: ['Germany', 'Italy', 'UK', 'US'],
        ageGroup: 2,
        respondent: 'Patient',
        instrument: 'HUI-3',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2016 [22]',
        meanUtility: 0.1,
        randomOffset: randomOffset(),
        age: 'Adults'
    },
    {
        // Excel Row: 18
        healthSubState: 'Adults with DMD; 96% on ventilation type NR',
        healthState: 'On ventilation',
        countries: ['Netherlands'],
        ageGroup: 2,
        respondent: 'Patient',
        instrument: 'EQ-5D',
        utilitySource: 'Patient',
        source: 'Pangalila et al., 2012 [29]',
        meanUtility: 0.44,
        sd: 0.13,
        randomOffset: randomOffset(),
        age: 'Adults'
    },
    {
        // Excel Row: 19
        healthSubState: 'Adults with DMD; 96% on ventilation type NR',
        healthState: 'On ventilation',
        countries: ['Netherlands'],
        ageGroup: 2,
        respondent: 'Patient',
        instrument: 'EQ-5D VAS',
        utilitySource: 'Patient',
        source: 'Pangalila et al., 2012 [29]', // ?
        meanUtility: 78,
        sd: 19,
        randomOffset: randomOffset(),
        age: 'Adults'
    },
    {
        // Excel Row: 20
        healthSubState: 'No ventilation',
        healthState: 'On ventilation',
        countries: ['UK'],
        ageGroup: 2,
        respondent: 'Patient',
        instrument: 'HUI-3',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2015 [25]',
        meanUtility: 0.52,
        sd: 0.03,
        randomOffset: randomOffset(),
        age: 'Adults'
    },
    {
        // Excel Row: 21
        healthSubState: 'Night-time ventilation',
        healthState: 'On ventilation',
        countries: ['UK'],
        ageGroup: 2,
        respondent: 'Patient',
        instrument: 'HUI-3',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2015 [25]',
        meanUtility: 0.13,
        sd: 0.02,
        randomOffset: randomOffset(),
        age: 'Adults'
    },
    {
        // Excel Row: 22
        healthSubState: 'Day- and night-time ventilation',
        healthState: 'On ventilation',
        countries: ['UK'],
        ageGroup: 2,
        respondent: 'Patient',
        instrument: 'HUI-3',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2015 [25]',
        meanUtility: 0.05,
        sd: 0.01,
        randomOffset: randomOffset(),
        age: 'Adults'
    },
    {
        // Excel Row: 24
        healthSubState: '70% <17 y',
        healthState: 'Mixed',
        countries: ['Bulgaria', 'France', 'Germany', 'Hungary', 'Italy', 'Spain', 'Sweden', 'UK'],
        ageGroup: 1,
        respondent: 'Patient',
        instrument: 'EQ-5D',
        utilitySource: 'Patient',
        source: 'Cavazza et al., 2016 [26]',
        meanUtility: 0.24,
        randomOffset: randomOffset(),
        age: '8-17; mean age 14.7'
    },
    {
        // Excel Row: 25
        healthSubState: '70% <17 y',
        healthState: 'Mixed',
        countries: ['Bulgaria', 'France', 'Germany', 'Hungary', 'Italy', 'Spain', 'Sweden', 'UK'],
        ageGroup: 1,
        respondent: 'Patient',
        instrument: 'EQ-5D VAS',
        utilitySource: 'Patient',
        source: 'Cavazza et al., 2016 [26]',
        meanUtility: 50.5,
        randomOffset: randomOffset(),
        age: '8-17 years; mean age 14.7'
    },
    {
        // Excel Row: 26
        healthSubState: 'Any DMD (rs)',
        healthState: 'Mixed',
        countries: ['Germany', 'Italy', 'UK', 'US'],
        ageGroup: 1,
        respondent: 'Patient',
        instrument: 'HUI-3',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2014 [24]', // Estimate
        meanUtility: 0.46,
        randomOffset: randomOffset(),
        age: 'Mean age 14'
    },
    // Caregiver Respondents
    {
        // Excel Row: 4
        healthSubState: 'Early ambulatory (age 5-7 y)',
        healthState: 'Ambulatory',
        countries: ['Germany', 'Italy', 'UK', 'US'],
        ageGroup: 0,
        respondent: 'Caregiver',
        instrument: 'EQ-5D',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2016 [22]',
        meanUtility: 0.85,
        sd: 0.19,
        randomOffset: randomOffset(),
        age: '5-7'
    },
    {
        // Excel Row: 5
        healthSubState: 'Early ambulatory (age 5-7 y)',
        healthState: 'Ambulatory',
        countries: ['Germany', 'Italy', 'UK', 'US'],
        ageGroup: 0,
        respondent: 'Caregiver',
        instrument: 'EQ-5D VAS',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2016 [23]',
        meanUtility: 76,
        randomOffset: randomOffset(),
        age: '5-7'
    },
    {
        // Excel Row: 6
        healthSubState: 'Late ambulatory (age 8-11 y)',
        healthState: 'Ambulatory',
        countries: ['Germany', 'Italy', 'UK', 'US'],
        ageGroup: 0,
        respondent: 'Caregiver',
        instrument: 'EQ-5D',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2016 [22]',
        meanUtility: 0.83,
        randomOffset: randomOffset(),
        age: '8-11'
    },
    {
        // Excel Row: 7
        healthSubState: 'Late ambulatory (age 8-11 y)',
        healthState: 'Ambulatory',
        countries: ['Germany', 'Italy', 'UK', 'US'],
        ageGroup: 0,
        respondent: 'Caregiver',
        instrument: 'EQ-5D VAS',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2016 [23]',
        meanUtility: 75,
        randomOffset: randomOffset(),
        age: '8-11'
    },
    {
        // Excel Row: 10
        healthSubState: 'Early non-ambulatory (age 12-15 y)',
        healthState: 'Non-ambulatory',
        countries: ['Germany', 'Italy', 'UK', 'US'],
        ageGroup: 1,
        respondent: 'Caregiver',
        instrument: 'EQ-5D',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2016 [22]',
        meanUtility: 0.77,
        sd: 0.03,
        randomOffset: randomOffset(),
        age: '12-15'
    },
    {
        // Excel Row: 11
        healthSubState: 'Early non-ambulatory (age 12-15 y)',
        healthState: 'Non-ambulatory',
        countries: ['Germany', 'Italy', 'UK', 'US'],
        ageGroup: 1,
        respondent: 'Caregiver',
        instrument: 'EQ-5D VAS',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2016 [23]',
        meanUtility: 71,
        randomOffset: randomOffset(),
        age: '12-15'
    },
    {
        // Excel Row: 12
        healthSubState: 'Late non-ambulatory (age 16+ y)',
        healthState: 'Non-ambulatory',
        countries: ['Germany', 'Italy', 'UK', 'US'],
        ageGroup: 1,
        respondent: 'Caregiver',
        instrument: 'EQ-5D',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2016 [22]',
        meanUtility: 0.79,
        randomOffset: randomOffset(),
        age: '16+'
    },
    {
        // Excel Row: 13
        healthSubState: 'Late non-ambulatory (age 16+ y)',
        healthState: 'Non-ambulatory',
        countries: ['Germany', 'Italy', 'UK', 'US'],
        ageGroup: 1,
        respondent: 'Caregiver',
        instrument: 'EQ-5D VAS',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2016 [23]',
        meanUtility: 74,
        randomOffset: randomOffset(),
        age: '16+'
    },
    {
        // Excel Row: 18
        healthSubState: 'Adults with DMD; 96% on ventilation type NR',
        healthState: 'On ventilation',
        countries: ['Netherlands'],
        ageGroup: 2,
        respondent: 'Caregiver',
        instrument: 'EQ-5D',
        utilitySource: 'Patient',
        source: 'Pangalila et al., 2012 [29]',
        meanUtility: 0.87,
        sd: 0.17,
        randomOffset: randomOffset(),
        age: 'Adults'
    },
    {
        // Excel Row: 19
        healthSubState: 'Adults with DMD; 96% on ventilation type NR',
        healthState: 'On ventilation',
        countries: ['Netherlands'],
        ageGroup: 2,
        respondent: 'Caregiver',
        instrument: 'EQ-5D VAS',
        utilitySource: 'Patient',
        source: 'Pangalila et al., 2012 [29]',
        meanUtility: 81,
        sd: 15,
        randomOffset: randomOffset(),
        age: 'Adults'
    },
    {
        // Excel Row: 20
        healthSubState: 'No ventilation',
        healthState: 'On ventilation',
        countries: ['UK'],
        ageGroup: 2,
        respondent: 'Caregiver',
        instrument: 'EQ-5D',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2015 [25]',
        meanUtility: 0.84,
        sd: 0.01,
        randomOffset: randomOffset(),
        age: 'Adults'
    },
    {
        // Excel Row: 21
        healthSubState: 'Night-time ventilation',
        healthState: 'On ventilation',
        countries: ['UK'],
        ageGroup: 2,
        respondent: 'Caregiver',
        instrument: 'EQ-5D',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2015 [25]',
        meanUtility: 0.78,
        sd: 0.03,
        randomOffset: randomOffset(),
        age: 'Adults'
    },
    {
        // Excel Row: 22
        healthSubState: 'Day- and night-time ventilation',
        healthState: 'On ventilation',
        countries: ['UK'],
        ageGroup: 2,
        respondent: 'Caregiver',
        instrument: 'EQ-5D',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2015 [25]',
        meanUtility: 0.77,
        sd: 0.03,
        randomOffset: randomOffset(),
        age: 'Adults'
    },
    {
        // Excel Row: 24
        healthSubState: '70% <17 y',
        healthState: 'Mixed',
        countries: ['Bulgaria', 'France', 'Germany', 'Hungary', 'Italy', 'Spain', 'Sweden', 'UK'],
        ageGroup: 1,
        respondent: 'Caregiver',
        instrument: 'EQ-5D',
        utilitySource: 'Patient',
        source: 'Cavazza et al., 2016 [26]',
        meanUtility: 0.71,
        randomOffset: randomOffset(),
        age: '8-17; mean age 14.7'
    },
    {
        // Excel Row: 25
        healthSubState: '70% <17 y',
        healthState: 'Mixed',
        countries: ['Bulgaria', 'France', 'Germany', 'Hungary', 'Italy', 'Spain', 'Sweden', 'UK'],
        ageGroup: 1,
        respondent: 'Caregiver',
        instrument: 'EQ-5D VAS',
        utilitySource: 'Patient',
        source: 'Cavazza et al., 2016 [26]',
        meanUtility: 74.7,
        randomOffset: randomOffset(),
        age: '8-17; mean age 14.7'
    },
    {
        // Excel Row: 26
        healthSubState: 'Any DMD (rs)',
        healthState: 'Mixed',
        countries: ['Germany', 'Italy', 'UK', 'US'],
        ageGroup: 1,
        respondent: 'Caregiver',
        instrument: 'EQ-5D',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2016 [22]',
        meanUtility: 0.81,
        randomOffset: randomOffset(),
        age: 'Mean age 14'
    },
    {
        // Excel Row: 27
        healthSubState: 'Any DMD (rs)',
        healthState: 'Mixed',
        countries: ['Germany', 'Italy', 'UK', 'US'],
        ageGroup: 1,
        respondent: 'Caregiver',
        instrument: 'EQ-5D VAS',
        utilitySource: 'Proxy',
        source: 'Landfeldt et al., 2016 [23]',
        meanUtility: 74,
        sd: 0.14,
        randomOffset: randomOffset(),
        age: 'Mean age 14'
    },
];

