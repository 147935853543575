import { Component } from '@angular/core';
import { EChartOption } from 'echarts';
import { Entry, data, HealthState, Respondent, Country, UtilitySource, Instrument } from './data';
import { of, concat, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { getSeries, options } from './chart.settings';

interface Filter {
  countries: Country[];
  sources: UtilitySource[];
  instruments: Instrument[];
}
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {
  public options = options;

  private filterSource = new BehaviorSubject<Filter>({
    countries: [],
    sources: [],
    instruments: []
  });
  public readonly filter$ = this.filterSource.asObservable();

  private filteredData$ = this.filter$.pipe(
    map((filter: Filter): Entry[] =>
      data.filter((entry: Entry): boolean =>
        (filter.countries.length === 0 || this.intersects(entry.countries, filter.countries))
        && (filter.sources.length === 0 || filter.sources.includes(entry.utilitySource))
        && (filter.instruments.length === 0 || filter.instruments.includes(entry.instrument))
      )
    )
  );

  public instruments: Instrument[] = ['HUI-3', 'EQ-5D', 'EQ-5D VAS'];

  public sources: UtilitySource[] = ['Proxy', 'Patient'];

  public countries = [
    { value: 'Bulgaria', label: 'BG' },
    { value: 'France', label: 'FR' },
    { value: 'Germany', label: 'DE' },
    { value: 'Hungary', label: 'HU' },
    { value: 'Italy', label: 'IT' },
    { value: 'Netherlands', label: 'NL' },
    { value: 'Spain', label: 'ES' },
    { value: 'Sweden', label: 'SE' },
    { value: 'UK', label: 'UK' },
    { value: 'US', label: 'US' },
  ];

  public merge$ = concat(
    of(null),
    this.filteredData$.pipe(
      map((d: Entry[]): EChartOption<EChartOption.Series> => this.getChart(d))
    )
  );

  private getChart(entries: Entry[]): EChartOption {
    type DataPoints = { [key in HealthState]: { value: [number, number], data: any }[] };
    const dataPointsPatient: DataPoints = {
      Ambulatory: [],
      'Non-ambulatory': [],
      'On ventilation': [],
      Mixed: []
    };
    const dataPointsCaregiver: DataPoints = {
      Ambulatory: [],
      'Non-ambulatory': [],
      'On ventilation': [],
      Mixed: []
    };
    entries.forEach((entry: Entry): void => {
      const x = entry.ageGroup * 33 + 16 + entry.randomOffset;
      if (entry.respondent === 'Patient') {
        const y = entry.meanUtility > 1 ? entry.meanUtility / 100 : entry.meanUtility;
        if (entry.healthState === 'On ventilation' && entry.healthSubState === 'No ventilation') {
          dataPointsPatient['Non-ambulatory'].push({ value: [x, y], data: { ...entry } });
        } else {
          dataPointsPatient[entry.healthState].push({ value: [x, y], data: { ...entry } });
        }
      } else {
        const y = entry.meanUtility < 1 ? entry.meanUtility * 100 : entry.meanUtility;
        dataPointsCaregiver[entry.healthState].push({ value: [x, y], data: { ...entry } });
      }
    });

    const getChartSeries = (dataPoints: DataPoints, chart: Respondent): EChartOption.SeriesScatter[] => {
      const s: EChartOption.SeriesScatter[] = [];
      for (const healthState in dataPoints) {
        if (dataPoints.hasOwnProperty(healthState)) {
          const dataArr = dataPoints[healthState];
          s.push(getSeries(dataArr, healthState as HealthState, chart));
        }
      }
      return s;
    };

    const series: EChartOption.SeriesScatter[] = [,
      getSeries([], '', 'Patient', true), // Age ranges on Patient chart
      getSeries([], '', 'Caregiver', true), // Age ranges on Caregiver chart
      ...getChartSeries(dataPointsPatient, 'Patient'), // Data on Patient chart
      ...getChartSeries(dataPointsCaregiver, 'Caregiver') // Data on Caregiver chart
    ];

    return {
      series
    };
  }



  public updateFilter<K extends keyof Filter>(filterKey: K, value: any): void {
    const currentFilter = this.filterSource.getValue();
    if (value === 'Any') {
      currentFilter[filterKey] = [];
    } else {
      const currentValues: any[] = currentFilter[filterKey];
      const existingValueIndex = currentValues.indexOf(value);
      if (existingValueIndex > -1) {
        currentValues.splice(existingValueIndex, 1);
      } else {
        currentValues.push(value);
      }
      if (
        (filterKey === 'countries' && currentValues.length === 10)
        || (filterKey === 'instruments' && currentValues.length === 3)
      ) {
        currentFilter[filterKey] = [];
      }

      if (filterKey === 'sources' && currentValues.length === 2) {
        currentFilter[filterKey] = [value];
      }
    }
    this.filterSource.next(currentFilter);
  }

  private intersects = (arr1: any[], arr2: any[]): boolean => arr1.some((e: any): boolean => arr2.includes(e));


}
