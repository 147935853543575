import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AUTH_APP_NAME, AUTH_ENDPOINT } from './auth.settings';

const storage = {
  ACCESS_TOKEN: 'dmd-utilities-access-token',
  EXPIRES_ON: 'dmd-utilities-expires-at'
};

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  public bypassAuth = false;

  // check at least once if the token is valid
  public serverAuthChecked = false;

  // returns true if the token hasn't expired yet
  public isNotExpired = (date: string): boolean => new Date().getTime() < Number(date) * 1000;

  private clearSession(): void {
    localStorage.removeItem(storage.ACCESS_TOKEN);
    localStorage.removeItem(storage.EXPIRES_ON);
  }

  private setSession(accessToken: string, expiryTime: string): void {
    localStorage.setItem(storage.ACCESS_TOKEN, accessToken);
    localStorage.setItem(storage.EXPIRES_ON, expiryTime);
  }

  constructor(private router: Router, private http: HttpClient) { }

  public logout = (): void => this.clearSession();
  public isAuthenticated = (): boolean => this.isNotExpired(localStorage.getItem(storage.EXPIRES_ON)) && this.serverAuthChecked;

  public allowedToLoad = (accessToken: string): void => {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + accessToken);
    this.http.get<any[]>(`${AUTH_ENDPOINT}/data?app=${AUTH_APP_NAME}`, { headers })
      .subscribe((results: any[]): void => {
        if (results && results['authorized'] === true) {
          this.serverAuthChecked = true;
          const decoded: JwtPayload = jwt_decode(accessToken);
          this.setSession(accessToken, decoded.exp.toString());
          this.router.navigate(['']);
        } else {
          this.tokenInvalid();
        }
    }, () => this.tokenInvalid());
  }

  public checkLocalCredentials = (): void => {
    const token = localStorage.getItem(storage.ACCESS_TOKEN);
    const expiryAt = localStorage.getItem(storage.EXPIRES_ON);
    if (token && expiryAt) {
      if (this.isNotExpired(expiryAt)) {
        this.allowedToLoad(token);
      }
    } else {
      this.router.navigate(['/expired']);
    }
  }

  private tokenInvalid = () => {
    this.clearSession();
    this.serverAuthChecked = false;
    this.router.navigate(['/expired']);
  }

}
