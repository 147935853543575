import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { map, filter } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>;
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed',
    platform: string
  }>;
  prompt(): Promise<void>;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private deferredPrompt: BeforeInstallPromptEvent;

  constructor(
    private route: ActivatedRoute,
    private modal: NzModalService,
  ) { }

  // Force A2HS Dialog, see https://developers.google.com/web/updates/2018/06/a2hs-updates
  @HostListener('window:beforeinstallprompt', ['$event'])
  public onBeforeInstallPrompt(e: BeforeInstallPromptEvent): void {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    this.deferredPrompt = e;
    this.route.queryParams.pipe(
      map((queryParams: Params): string => queryParams.install),
      filter((install: string): boolean => !!install)
    ).subscribe((): void => {
      this.modal.create({
        nzTitle: 'Install',
        nzContent: 'In order to be able to use this application offline it needs to be installed. Would you like to proceed?',
        nzClosable: true,
        nzOnOk: (): void => this.addToHomeScreen()
      });
    });
  }

  private addToHomeScreen(): void {
    // Show the prompt
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
    }
  }

}
