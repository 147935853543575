import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
    ) {}

  public canActivate(): boolean {
    if (this.authService.isAuthenticated() || this.authService.bypassAuth) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
