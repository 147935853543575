import { EChartOption } from 'echarts';
import { HealthState, Respondent, Entry } from './data';

const colors = {
    Ambulatory: '#CDA349',
    'Non-ambulatory': '#9B9B9B',
    'On ventilation': '#5781A3',
    Mixed: '#990099'
};

const areas = {
    silent: true,
    data: [
        [{
            name: 'Early childhood\n (<11 years)',
            xAxis: 0,
            itemStyle: {
                color: 'rgba(84, 51, 80, 0.3)'
            },
            label: {
                position: 'bottom', // 'insideBottom',
                // verticalAlign: 'middle',
                // distance: 20,
                color: '#606060'
            }
        }, {
            xAxis: 33
        }],
        [{
            name: 'Late childhood /\n adolescence\n (11-17 years)',
            xAxis: 33,
            itemStyle: {
                color: 'rgba(155, 155, 155, 0.35)'
            },
            label: {
                position: 'bottom', // 'insideBottom',
                color: '#606060'
            }
        }, {
            xAxis: 66
        }],
        [{
            name: 'Adulthood\n (18+)',
            xAxis: 66,
            itemStyle: {
                color: 'rgba(87, 129, 163, 0.35)'
            },
            label: {
                position: 'bottom', // 'insideBottom',
                color: '#606060'
            },
        }, {
            xAxis: 100
        }],
    ]
};

export const getSeries = (
    data: { value: [number, number], data: any }[],
    healthState: HealthState | '',
    chart: Respondent,
    addMarkArea: boolean = false
): EChartOption.SeriesScatter => {
    const gridIndex = new Map([['Patient', 0], ['Caregiver', 1]]);
    return {
        name: healthState,
        symbolSize: 14,
        data,
        type: 'scatter',
        itemStyle: {
            color: colors[healthState],
            borderColor: 'white',
            borderWidth: 1
        },
        markArea: addMarkArea ? areas as any : null,
        xAxisIndex: gridIndex.get(chart),
        yAxisIndex: gridIndex.get(chart),
    };
};

export const options: EChartOption = {
    tooltip: {
        trigger: 'item',
        formatter: (e: {data: {data: Entry}}): string => {
            const entry = e.data.data;
            const meanWord = entry.instrument === 'EQ-5D VAS' ? 'score' : 'utility';
            return `
            Mean ${meanWord} : <strong>${entry.meanUtility}${entry.sd ? ' (' + entry.sd + ')' : ''}</strong><br>
            Age: ${entry.age || 'unknown'}<br>
            Instrument: ${entry.instrument}<br>
            Source: ${entry.source}
            `;
        }
    },
    grid: [
        {
            id: 'patient',
            top: 80,
            right: '50%',
            bottom: 50,
            left: '10%'
        },
        {
            id: 'caregiver',
            top: 80,
            right: '10%',
            bottom: 50,
            left: '50%'
        }
    ],

    xAxis: [
        {
            gridIndex: 0,
            min: 0,
            max: 105,
            splitLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    color: '#56004E',
                    width: 2
                }
            },
            axisLabel: {
                show: false
            }
        },
        {
            gridIndex: 1,
            min: -5,
            max: 100,
            splitLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    color: '#56004E',
                    width: 2
                }
            },
            axisLabel: {
                show: false
            }
        }
    ],
    yAxis: [{
        gridIndex: 0,
        max: 1,
        // min: -0.2,
        min: 0,
        name: 'HUI-3/EQ-5D',
        nameLocation: 'center',
        nameGap: 40,
        interval: 0.1,
        splitLine: {
            lineStyle: {
                type: 'dotted',
                color: 'rgba(87, 129, 163, 0.5)'
            }
        },
        axisTick: {
            length: 10,
            lineStyle: {
                type: 'dotted',
                color: 'rgba(87, 129, 163, 0.5)'
            }
        },
        axisLine: {
            show: false
        },
        axisLabel: {
            // formatter: (v: number): string => v >= 0 ? v.toString() : '',
            margin: 15,
            // showMinLabel: false
        }
    },
    {
        gridIndex: 1,
        max: 100,
        // min: -20,
        min: 0,
        position: 'right',
        name: 'VAS',
        nameLocation: 'center',
        nameGap: 40,
        interval: 10,
        splitLine: {
            lineStyle: {
                type: 'dotted',
                color: 'rgba(87, 129, 163, 0.5)'
            }
        },
        axisTick: {
            length: 10,
            lineStyle: {
                type: 'dotted',
                color: 'rgba(87, 129, 163, 0.5)'
            }
        },
        axisLine: {
            show: false,
            onZero: false
        },
        axisLabel: {
            // formatter: (v: number): string => v >= 0 ? v.toString() : '',
            margin: 15,
            // showMinLabel: false
        }
    }
    ],
    legend: {
        left: 190,
        top: 20,
        textStyle: {
            color: '#606060'
        },
        data: ['Ambulatory', 'Non-ambulatory', 'On ventilation', 'Mixed']
    },
    series: [],
    graphic: [
        {
            type: 'text',
            left: '30',
            top: '25',
            z: 100,
            style: {
                fill: '#606060',
                text: 'HEALTH STATES',
                font: 'normal 18px sans-serif'
            },
            silent: true
        },
        {
            type: 'text',
            left: '20%',
            top: '60',
            z: 100,
            style: {
                fill: '#56004E',
                text: 'Patient respondents',
                font: 'normal 16px sans-serif'
            },
            silent: true
        },
        {
            type: 'text',
            left: '60%',
            top: '60',
            z: 100,
            style: {
                fill: '#56004E',
                text: 'Caregiver respondents',
                font: 'normal 16px sans-serif'
            },
            silent: true
        }
    ],
    textStyle: {
        color: '#606060'
    }
};
