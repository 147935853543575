import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth/auth.service';

@Component({
  selector: 'app-login',
  template: '',
})
export class LoginComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
) {}

  public ngOnInit(): void {

    if (this.authService.bypassAuth) {
      this.router.navigate(['']);
    } else {
      if (environment.portfolio) {
        this.route.queryParams.subscribe((params: Params): void => {
          if (params.accessToken) {
            this.authService.allowedToLoad(params.accessToken);
          } else {
            this.authService.checkLocalCredentials();
          }
        });
      } else {
        this.router.navigate(['/expired']);
      }
    }
  }
}
